@import url(./global.css);

.app {
    width: 100%;
    transition: var(--transition);
    background-color: var(--background-color);

    // ---- ---- layout ---- ---- //

    .clase {
        background-color: var(--color-black);
    }

    .header {
        top: 0px;
        z-index: 12;
        width: 100%;
        height: 80px;
        display: flex;
        position: fixed;
        align-items: center;
        justify-content: center;
        transition: var(--transition);
        background-color: var(--color-white);
        border-bottom: 1px solid var(--border-color);

        .container {
            align-items: center;
            justify-content: space-between;

            .flex {
                gap: 20px;
                width: 100%;
                display: flex;
                align-items: center;

                .nav-menu {
                    height: 34px;
                    display: flex;
                    align-items: center;

                    .nav-list {
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        list-style: none;
                        align-items: center;

                        .nav-item {
                            height: 100%;
                            display: flex;
                            padding: 0 10px;
                            align-items: center;
                            justify-content: center;

                            .nav-link {
                                text-decoration: none;
                                min-width: max-content;
                                color: var(--color-black);
                                transition: var(--transition);
                                font: normal 14px var(--font-sans);
                            }

                            &:hover {

                                .nav-link {
                                    text-decoration: underline;
                                }
                            }

                            &:last-child {
                                display: none !important;
                            }
                        }
                    }
                }

                #btnAction {
                    display: flex;
                }

                #btnMenu {
                    display: none;
                }

                &:nth-child(1) {
                    justify-content: flex-start;
                }

                &:nth-child(2) {
                    justify-content: flex-end;
                }
            }
        }
    }

    .menu {
        top: 0;
        z-index: 16;
        right: -320px;
        width: 320px;
        height: 100vh;
        display: flex;
        position: fixed;
        align-items: center;
        flex-direction: column;
        transition: var(--transition);
        background-color: var(--color-white);
        border-left: 1px solid var(--border-color);

        .menu-header {
            width: 100%;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            border-bottom: 1px solid var(--border-color);

            button {
                margin-right: 24px;
            }
        }

        .menu-content {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .nav-menu {
                width: 100%;
                display: flex;
                flex-direction: column;

                .nav-list {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    display: flex;
                    list-style: none;
                    flex-direction: column;

                    .nav-item {
                        width: 100%;
                        height: 60px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: 1px solid var(--border-color);

                        .nav-link {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            padding-left: 50px;
                            position: relative;
                            align-items: center;
                            text-decoration: none;
                            justify-content: flex-start;
                            transition: var(--transition);
                            font: normal 12px var(--font-sans);
                            color: #757575;

                            svg {
                                right: 50px;
                                position: absolute;
                            }
                        }

                        &:hover {

                            .nav-link {
                                color: var(--color-black);
                            }
                        }
                    }
                }

                .nav-link {
                    height: 50px;
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    justify-content: center;
                    color: var(--color-black);
                    transition: var(--transition);
                    font: normal 12px var(--font-sans);
                    text-transform: capitalize;
                    letter-spacing: .4px;

                    .nav-title {
                        z-index: 2;
                        height: 100%;
                        display: flex;
                        padding: 0 10px;
                        align-items: center;
                        background-color: var(--color-white);
                    }

                    .nav-icon {
                        z-index: 1;
                        transition: var(--transition);
                        transform: translateX(-14px);
                    }

                    &:hover {
                        text-decoration: underline;

                        .nav-icon {
                            transform: translateX(4px);
                        }
                    }
                }
            }
        }
    }

    .menu.show {
        right: 0;
    }

    .main {
        width: 100%;
        display: flex;
        overflow: hidden;
        margin-top: 80px;
        min-height: 700px;
        align-items: center;
        padding-bottom: 40px;
        flex-direction: column;
        justify-content: flex-start;

        .section {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .container {
                flex-direction: column;

                .head-title {
                    display: flex;
                    min-height: 240px;
                    align-items: center;
                    justify-content: center;
                    border-bottom: 1px solid var(--border-color);

                    h1,
                    h2 {
                        width: 100%;
                        text-align: center;
                        text-shadow: 2px 2px 2px grey;
                        font: bold 50px var(--font-genos);
                        text-transform: uppercase;
                    }
                }

                .row-title,
                .row-action {
                    display: flex;
                    padding: 34px 0px;
                    align-items: center;
                    flex-direction: row;
                }

                .row-title {
                    justify-content: space-between;

                    h2 {
                        margin: 0;
                        font: bold 24px var(--font-sans);
                    }
                }

                .row-caption {
                    gap: 10px;
                    display: flex;
                    padding: 40px 0px;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    h1,
                    h2,
                    p {
                        margin: 0;
                        text-wrap: balance;
                        text-align: center;
                        font-family: var(--font-sans);
                    }

                    h1,
                    h2 {
                        font-size: 26px;
                        font-weight: bold;
                    }

                    p {
                        font-size: 14px;
                        font-weight: normal;
                    }

                    .line {
                        width: 60px;
                        height: 1px;
                        pointer-events: none;
                        background-color: var(--border-color);
                    }
                }

                p {
                    margin: 20px 0;
                    text-wrap: balance;
                    font: normal 12px / 1.5 var(--font-sans);

                    b {
                        font: bold 14px var(--font-sans);
                    }
                }

                .row {
                    gap: 20px;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                }

                .column {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                }

                .feed {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    .brand {
                        display: none;

                        img {
                            width: 100%;
                            pointer-events: none;
                        }
                    }
                }

                .brand {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    img {
                        width: 480px;
                        display: flex;
                        margin-top: 30px;
                        aspect-ratio: 1/1;
                        object-fit: cover;
                        pointer-events: none;
                        object-position: center;
                        border: 1px dashed var(--border-color);
                    }
                }

                .row-action {
                    gap: 18px;
                    justify-content: flex-end;
                }
            }
        }
    }

    .footer {
        width: 100%;
        display: flex;
        padding: 20px 0;
        justify-content: center;
        transition: var(--transition);
        background-color: var(--footer-color);
        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);

        .container {
            flex-direction: column;

            .footer-socials {
                display: flex;
                align-items: center;
                padding-bottom: 20px;
                justify-content: center;

                .social-list {
                    gap: 20px;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    list-style: none;
                    align-items: center;
                    justify-content: center;

                    .social-item {
                        width: 24px;
                        height: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .social-link {
                            font-size: 22px;
                            text-decoration: none;
                            color: var(--color-black);
                        }
                    }
                }
            }

            .footer-copyright {
                display: flex;
                padding: 14px 0;
                align-items: center;
                justify-content: center;

                .copyright {
                    text-align: center;
                    min-width: max-content;
                    color: var(--color-black);
                    text-transform: uppercase;
                    font: normal 10px var(--font-sans);

                    a {
                        color: inherit;
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                            color: var(--primary-color);
                        }
                    }
                }
            }
        }
    }

    // ---- ---- components ---- ---- //

    .carousel {
        width: 100%;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;

        .carousel-images {
            width: 100%;
            height: 50vh;
            display: flex;
            pointer-events: none;
            justify-content: center;

            .images {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }

            #carouselMobile {
                display: none;
            }
        }

        .carousel-controls {
            width: 100%;
            height: 40px;
            display: flex;
            position: absolute;
            align-items: center;
            justify-content: space-between;

            button {
                margin: 0 30px;
            }
        }

        button {
            opacity: 0;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            color: var(--color-black);
            transition: var(--transition);
            background-color: #ffffffbc;
        }

        #btnPause {
            right: 30px;
            bottom: 30px;
            position: absolute;
            color: var(--color-white);
            font-size: 16px !important;
            background-color: transparent;
            border: 1px solid var(--border-color);

            &:hover {
                opacity: 1;
            }
        }

        &:hover {

            #btnPrev,
            #btnNext {
                opacity: 1;
            }
        }
    }

    .glass {
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 14;
        width: 100%;
        height: 100vh;
        position: fixed;
        visibility: hidden;
        transition: var(--transition);
        background-color: var(--glass-color);
    }

    .glass.show {
        opacity: 1;
        visibility: visible;
    }

    .item-box {
        width: 100%;
        height: 200px;
        display: flex;
        cursor: pointer;
        overflow: hidden;
        position: relative;
        flex-direction: column;

        .item-box-image {
            z-index: 1;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                height: 100%;
                display: flex;
                object-fit: cover;
                object-position: center;
            }
        }

        .item-box-overlay {
            inset: 0px;
            z-index: 2;
            width: 100%;
            height: 100%;
            opacity: 0.4;
            display: flex;
            position: fixed;
            position: absolute;
            background: linear-gradient(to bottom, #00c9dc, #00587e 84%);
        }

        .item-box-caption {
            gap: 12px;
            z-index: 3;
            display: flex;
            padding: 20px;
            bottom: -38px;
            position: absolute;
            flex-direction: column;
            align-items: flex-start;
            width: calc(100% - 40px);
            transition: var(--transition);
            background: linear-gradient(to top, #202020, #00000000 100%);

            .title {
                color: var(--color-white);
                font: bold 20px var(--font-sans);
            }

            .desc {
                min-height: 18px;
                color: var(--color-white);
                font: normal 12px var(--font-sans);
            }
        }

        &:hover {

            .item-box-caption {
                bottom: 0;
                gap: 10px;
            }
        }
    }

    .service-row {
        width: 100%;
        display: flex;
        padding-bottom: 40px;

        .service-row-image {
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                object-fit: cover;
                aspect-ratio: 16/9;
                pointer-events: none;
                object-position: center center;
            }
        }

        .service-row-data {
            gap: 34px;
            width: 50%;
            display: flex;
            margin-left: 30px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            h2 {
                margin: 0;
                width: 100%;
                text-wrap: balance;
                font: bold 26px var(--font-sans);
            }

            p {
                margin: 0;
                text-wrap: balance;
                line-height: 1.8 !important;
                font: normal 16px var(--font-sans);
            }
        }

        &:first-child {
            padding-top: 40px !important;
        }
    }

    .service-row-load {
        width: 100%;
        display: flex;
        padding: 40px 0px;

        .service-row-image-load {
            width: 40%;
            aspect-ratio: 16/9;
            animation: var(--loading);
            transition: var(--transition);
        }

        .service-row-data-load {
            gap: 34px;
            width: 50%;
            display: flex;
            margin-left: 30px;
            flex-direction: column;
            align-items: flex-start;

            .title {
                gap: 8px;
                width: 100%;
                display: flex;
                flex-direction: column;

                span {
                    width: 80%;
                    height: 34px;
                    border-radius: 8px;
                    animation: var(--loading);
                    transition: var(--transition);

                    &:last-child {
                        width: 65%;
                    }
                }
            }

            .desc {
                gap: 5px;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                span {
                    width: 80%;
                    height: 24px;
                    border-radius: 8px;
                    animation: var(--loading);
                    transition: var(--transition);

                    &:nth-child(2) {
                        width: 76%;
                    }

                    &:nth-child(3) {
                        width: 78%;
                    }
                }
            }

            .button {
                width: 120px;
                height: 36px;
                border-radius: 8px;
                animation: var(--loading);
                transition: var(--transition);
            }
        }
    }

    .contact-row {
        display: flex;

        .contact-row-icon {
            width: 26px;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            .icon {
                font-size: 12px;
                color: var(--primary-color);
            }
        }

        .contact-row-data {
            gap: 4px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .title {
                margin-bottom: 10px;
                color: var(--primary-color);
                font: bold 12px var(--font-sans);
            }

            .link {
                margin-bottom: 10px;
                color: var(--color-black);
                font: normal 18px var(--font-sans);
            }
        }
    }

    // ---- ---- classes ---- ---- //

    .container {
        display: flex;
        margin: 0 20px;
        width: var(--wrapper);
    }

    .logo {
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: var(--transition);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            pointer-events: none;
            object-position: center center;
        }
    }

    hr {
        width: 100%;
        height: 1px;
        border: none;
        transition: var(--transition);
        background-color: var(--border-color);
    }

    .grid {
        gap: 18px;
        width: 100%;
        display: grid;
        padding: 40px 0;
        grid-template-columns: repeat(4, 1fr);
    }

    .no-found {
        gap: 20px;
        width: 100%;
        display: flex;
        min-height: 78vh;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .icon-no-found {
            z-index: 1;
            opacity: 0.8;
            position: absolute;
            pointer-events: none;
            color: var(--border-color);
            line-height: 400px !important;
            font: 700 400px var(--font-genos);
        }

        .title-no-found,
        .text-no-found,
        a {
            z-index: 2;
        }

        .title-no-found {
            font: bold 80px var(--font-genos);
            text-shadow: 2px 2px 10px var(--color-black);
        }

        .text-no-found {
            text-align: center;
            letter-spacing: .4px;
            line-height: 30px !important;
            font: normal 16px var(--font-sans);
            text-shadow: 2px 2px 10px var(--color-black);

            br {
                display: none;
            }
        }

        a {
            box-shadow: 2px 2px 10px var(--color-black);
        }
    }
}

// ---- ---- responsive ---- ---- //

@media screen and (max-width: 64em) {

    .header {

        .container {

            .flex {

                .nav-menu {
                    display: none !important;
                }

                #btnMenu {
                    display: flex !important;
                }
            }
        }
    }

    .main {
        .container {
            .row {
                flex-direction: column !important;

                .feed {

                    p {
                        width: calc(100% - 30%);
                        text-align: left;
                        text-wrap: balance;

                        &:first-child {
                            text-align: center !important;
                        }

                        &:nth-child(even) {
                            text-align: right;
                        }
                    }

                    .brand {
                        display: flex !important;

                        img {
                            margin-top: 0px !important;
                            width: calc(100% - 30%) !important;
                        }
                    }
                }

                .brand {
                    display: none !important;
                }
            }
        }
    }

    // ---- ---- components ---- ---- //

    .carousel {

        .carousel-images {
            height: 270px !important;
            // height: 180px !important;

            .images {
                object-position: center !important;
            }
        }
    }

    .service-row {

        .service-row-image {

            img {
                height: 100%;
                aspect-ratio: none;
            }
        }

        .service-row-data {
            gap: 18px !important;

            h2 {
                font-size: 18px !important;
            }

            p {
                font-size: 13px !important;
            }
        }
    }

    .service-row-load {

        .service-row-data-load {
            gap: 20px !important;

            .title {

                span {
                    height: 30px !important;
                }
            }

            .desc {

                span {
                    height: 20px !important;
                }
            }
        }
    }

    .contact-row {

        .contact-row-data {

            .link {
                font-size: 16px !important;
            }
        }
    }

    // ---- ---- classes ---- ---- //

    .logo {
        height: 48px !important;
    }

    .grid {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .no-found {

        .icon-no-found {
            font-size: 40vh !important;
        }

        .title-no-found {
            font-size: 40px !important;
        }

        .text-no-found {

            br {
                display: flex !important;
            }
        }
    }
}

@media screen and (max-width: 640px) {
    .grid {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

@media screen and (max-width: 40em) {

    // ---- ---- layout ---- ---- //

    .header {

        .container {

            .flex {
                gap: 0px !important;

                .nav-menu {
                    display: none !important;
                }

                #btnAction {
                    display: none !important;
                }

                #btnMenu {
                    display: flex !important;
                }

                &:nth-child(1) {
                    width: 100%;
                }

                &:nth-child(2) {
                    width: 30%;
                }
            }
        }
    }

    .main {

        .section {

            .container {

                .head-title {
                    min-height: 180px !important;
                }

                .row {

                    p {
                        width: 100% !important;
                        text-align: center !important;
                    }

                    .brand {

                        img {
                            width: 100% !important;
                            display: flex !important;
                        }
                    }
                }
            }
        }
    }

    // ---- ---- components ---- ---- //

    .carousel {

        .carousel-images {
            height: 210px !important;
            height: auto !important;

            #carouselDestok {
                display: none;
            }

            #carouselMobile {
                display: flex !important;
            }
        }
    }

    .service-row {
        flex-direction: column;

        .service-row-image {
            width: 100% !important;
        }

        .service-row-data {
            width: 100% !important;
            margin-left: 0 !important;
            align-items: center !important;

            h2 {
                text-align: center;
                margin-top: 14px !important;
            }

            p {
                text-align: center;
                font-size: 12px !important;
            }
        }
    }

    .service-row-load {
        flex-direction: column;

        .service-row-image-load {
            width: 100% !important;
        }

        .service-row-data-load {
            width: 100% !important;
            margin-left: 0px !important;
            align-items: center !important;

            .title {
                margin-top: 14px;
                align-items: center !important;
            }

            .desc {
                align-items: center !important;
            }
        }
    }

    // ---- ---- classes ---- ---- //

    .logo {
        img {
            object-fit: fill !important;
        }
    }

    .no-found {

        .icon-no-found {
            font-size: 22vh !important;
        }
    }
}

@media screen and (max-width: 480px) {
    .grid {
        grid-template-columns: repeat(1, 1fr) !important;
    }
}

// ---- ---- imports ---- ---- //

@import url(./content.css);

@import url(./global.css);

@import url(./buttons.css);