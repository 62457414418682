@import url(./global.css);
@import url(./content.css);
@import url(./global.css);
@import url(./buttons.css);
.app {
  width: 100%;
  transition: var(--transition);
  background-color: var(--background-color);
}
.app .clase {
  background-color: var(--color-black);
}
.app .header {
  top: 0px;
  z-index: 12;
  width: 100%;
  height: 80px;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
  background-color: var(--color-white);
  border-bottom: 1px solid var(--border-color);
}
.app .header .container {
  align-items: center;
  justify-content: space-between;
}
.app .header .container .flex {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: center;
}
.app .header .container .flex .nav-menu {
  height: 34px;
  display: flex;
  align-items: center;
}
.app .header .container .flex .nav-menu .nav-list {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  list-style: none;
  align-items: center;
}
.app .header .container .flex .nav-menu .nav-list .nav-item {
  height: 100%;
  display: flex;
  padding: 0 10px;
  align-items: center;
  justify-content: center;
}
.app .header .container .flex .nav-menu .nav-list .nav-item .nav-link {
  text-decoration: none;
  min-width: -moz-max-content;
  min-width: max-content;
  color: var(--color-black);
  transition: var(--transition);
  font: normal 14px var(--font-sans);
}
.app .header .container .flex .nav-menu .nav-list .nav-item:hover .nav-link {
  text-decoration: underline;
}
.app .header .container .flex .nav-menu .nav-list .nav-item:last-child {
  display: none !important;
}
.app .header .container .flex #btnAction {
  display: flex;
}
.app .header .container .flex #btnMenu {
  display: none;
}
.app .header .container .flex:nth-child(1) {
  justify-content: flex-start;
}
.app .header .container .flex:nth-child(2) {
  justify-content: flex-end;
}
.app .menu {
  top: 0;
  z-index: 16;
  right: -320px;
  width: 320px;
  height: 100vh;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: column;
  transition: var(--transition);
  background-color: var(--color-white);
  border-left: 1px solid var(--border-color);
}
.app .menu .menu-header {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid var(--border-color);
}
.app .menu .menu-header button {
  margin-right: 24px;
}
.app .menu .menu-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app .menu .menu-content .nav-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.app .menu .menu-content .nav-menu .nav-list {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  list-style: none;
  flex-direction: column;
}
.app .menu .menu-content .nav-menu .nav-list .nav-item {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
}
.app .menu .menu-content .nav-menu .nav-list .nav-item .nav-link {
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 50px;
  position: relative;
  align-items: center;
  text-decoration: none;
  justify-content: flex-start;
  transition: var(--transition);
  font: normal 12px var(--font-sans);
  color: #757575;
}
.app .menu .menu-content .nav-menu .nav-list .nav-item .nav-link svg {
  right: 50px;
  position: absolute;
}
.app .menu .menu-content .nav-menu .nav-list .nav-item:hover .nav-link {
  color: var(--color-black);
}
.app .menu .menu-content .nav-menu .nav-link {
  height: 50px;
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  color: var(--color-black);
  transition: var(--transition);
  font: normal 12px var(--font-sans);
  text-transform: capitalize;
  letter-spacing: 0.4px;
}
.app .menu .menu-content .nav-menu .nav-link .nav-title {
  z-index: 2;
  height: 100%;
  display: flex;
  padding: 0 10px;
  align-items: center;
  background-color: var(--color-white);
}
.app .menu .menu-content .nav-menu .nav-link .nav-icon {
  z-index: 1;
  transition: var(--transition);
  transform: translateX(-14px);
}
.app .menu .menu-content .nav-menu .nav-link:hover {
  text-decoration: underline;
}
.app .menu .menu-content .nav-menu .nav-link:hover .nav-icon {
  transform: translateX(4px);
}
.app .menu.show {
  right: 0;
}
.app .main {
  width: 100%;
  display: flex;
  overflow: hidden;
  margin-top: 80px;
  min-height: 700px;
  align-items: center;
  padding-bottom: 40px;
  flex-direction: column;
  justify-content: flex-start;
}
.app .main .section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app .main .section .container {
  flex-direction: column;
}
.app .main .section .container .head-title {
  display: flex;
  min-height: 240px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--border-color);
}
.app .main .section .container .head-title h1,
.app .main .section .container .head-title h2 {
  width: 100%;
  text-align: center;
  text-shadow: 2px 2px 2px grey;
  font: bold 50px var(--font-genos);
  text-transform: uppercase;
}
.app .main .section .container .row-title,
.app .main .section .container .row-action {
  display: flex;
  padding: 34px 0px;
  align-items: center;
  flex-direction: row;
}
.app .main .section .container .row-title {
  justify-content: space-between;
}
.app .main .section .container .row-title h2 {
  margin: 0;
  font: bold 24px var(--font-sans);
}
.app .main .section .container .row-caption {
  gap: 10px;
  display: flex;
  padding: 40px 0px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.app .main .section .container .row-caption h1,
.app .main .section .container .row-caption h2,
.app .main .section .container .row-caption p {
  margin: 0;
  text-wrap: balance;
  text-align: center;
  font-family: var(--font-sans);
}
.app .main .section .container .row-caption h1,
.app .main .section .container .row-caption h2 {
  font-size: 26px;
  font-weight: bold;
}
.app .main .section .container .row-caption p {
  font-size: 14px;
  font-weight: normal;
}
.app .main .section .container .row-caption .line {
  width: 60px;
  height: 1px;
  pointer-events: none;
  background-color: var(--border-color);
}
.app .main .section .container p {
  margin: 20px 0;
  text-wrap: balance;
  font: normal 12px/1.5 var(--font-sans);
}
.app .main .section .container p b {
  font: bold 14px var(--font-sans);
}
.app .main .section .container .row {
  gap: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.app .main .section .container .column {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.app .main .section .container .feed {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.app .main .section .container .feed .brand {
  display: none;
}
.app .main .section .container .feed .brand img {
  width: 100%;
  pointer-events: none;
}
.app .main .section .container .brand {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.app .main .section .container .brand img {
  width: 480px;
  display: flex;
  margin-top: 30px;
  aspect-ratio: 1/1;
  -o-object-fit: cover;
     object-fit: cover;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
  border: 1px dashed var(--border-color);
}
.app .main .section .container .row-action {
  gap: 18px;
  justify-content: flex-end;
}
.app .footer {
  width: 100%;
  display: flex;
  padding: 20px 0;
  justify-content: center;
  transition: var(--transition);
  background-color: var(--footer-color);
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}
.app .footer .container {
  flex-direction: column;
}
.app .footer .container .footer-socials {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  justify-content: center;
}
.app .footer .container .footer-socials .social-list {
  gap: 20px;
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
}
.app .footer .container .footer-socials .social-list .social-item {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app .footer .container .footer-socials .social-list .social-item .social-link {
  font-size: 22px;
  text-decoration: none;
  color: var(--color-black);
}
.app .footer .container .footer-copyright {
  display: flex;
  padding: 14px 0;
  align-items: center;
  justify-content: center;
}
.app .footer .container .footer-copyright .copyright {
  text-align: center;
  min-width: -moz-max-content;
  min-width: max-content;
  color: var(--color-black);
  text-transform: uppercase;
  font: normal 10px var(--font-sans);
}
.app .footer .container .footer-copyright .copyright a {
  color: inherit;
  text-decoration: none;
}
.app .footer .container .footer-copyright .copyright a:hover {
  text-decoration: underline;
  color: var(--primary-color);
}
.app .carousel {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
}
.app .carousel .carousel-images {
  width: 100%;
  height: 50vh;
  display: flex;
  pointer-events: none;
  justify-content: center;
}
.app .carousel .carousel-images .images {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center center;
     object-position: center center;
}
.app .carousel .carousel-images #carouselMobile {
  display: none;
}
.app .carousel .carousel-controls {
  width: 100%;
  height: 40px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: space-between;
}
.app .carousel .carousel-controls button {
  margin: 0 30px;
}
.app .carousel button {
  opacity: 0;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  color: var(--color-black);
  transition: var(--transition);
  background-color: rgba(255, 255, 255, 0.737254902);
}
.app .carousel #btnPause {
  right: 30px;
  bottom: 30px;
  position: absolute;
  color: var(--color-white);
  font-size: 16px !important;
  background-color: transparent;
  border: 1px solid var(--border-color);
}
.app .carousel #btnPause:hover {
  opacity: 1;
}
.app .carousel:hover #btnPrev,
.app .carousel:hover #btnNext {
  opacity: 1;
}
.app .glass {
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 14;
  width: 100%;
  height: 100vh;
  position: fixed;
  visibility: hidden;
  transition: var(--transition);
  background-color: var(--glass-color);
}
.app .glass.show {
  opacity: 1;
  visibility: visible;
}
.app .item-box {
  width: 100%;
  height: 200px;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  flex-direction: column;
}
.app .item-box .item-box-image {
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app .item-box .item-box-image img {
  width: 100%;
  height: 100%;
  display: flex;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.app .item-box .item-box-overlay {
  inset: 0px;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  display: flex;
  position: fixed;
  position: absolute;
  background: linear-gradient(to bottom, #00c9dc, #00587e 84%);
}
.app .item-box .item-box-caption {
  gap: 12px;
  z-index: 3;
  display: flex;
  padding: 20px;
  bottom: -38px;
  position: absolute;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - 40px);
  transition: var(--transition);
  background: linear-gradient(to top, #202020, rgba(0, 0, 0, 0) 100%);
}
.app .item-box .item-box-caption .title {
  color: var(--color-white);
  font: bold 20px var(--font-sans);
}
.app .item-box .item-box-caption .desc {
  min-height: 18px;
  color: var(--color-white);
  font: normal 12px var(--font-sans);
}
.app .item-box:hover .item-box-caption {
  bottom: 0;
  gap: 10px;
}
.app .service-row {
  width: 100%;
  display: flex;
  padding-bottom: 40px;
}
.app .service-row .service-row-image {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app .service-row .service-row-image img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  aspect-ratio: 16/9;
  pointer-events: none;
  -o-object-position: center center;
     object-position: center center;
}
.app .service-row .service-row-data {
  gap: 34px;
  width: 50%;
  display: flex;
  margin-left: 30px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.app .service-row .service-row-data h2 {
  margin: 0;
  width: 100%;
  text-wrap: balance;
  font: bold 26px var(--font-sans);
}
.app .service-row .service-row-data p {
  margin: 0;
  text-wrap: balance;
  line-height: 1.8 !important;
  font: normal 16px var(--font-sans);
}
.app .service-row:first-child {
  padding-top: 40px !important;
}
.app .service-row-load {
  width: 100%;
  display: flex;
  padding: 40px 0px;
}
.app .service-row-load .service-row-image-load {
  width: 40%;
  aspect-ratio: 16/9;
  animation: var(--loading);
  transition: var(--transition);
}
.app .service-row-load .service-row-data-load {
  gap: 34px;
  width: 50%;
  display: flex;
  margin-left: 30px;
  flex-direction: column;
  align-items: flex-start;
}
.app .service-row-load .service-row-data-load .title {
  gap: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.app .service-row-load .service-row-data-load .title span {
  width: 80%;
  height: 34px;
  border-radius: 8px;
  animation: var(--loading);
  transition: var(--transition);
}
.app .service-row-load .service-row-data-load .title span:last-child {
  width: 65%;
}
.app .service-row-load .service-row-data-load .desc {
  gap: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.app .service-row-load .service-row-data-load .desc span {
  width: 80%;
  height: 24px;
  border-radius: 8px;
  animation: var(--loading);
  transition: var(--transition);
}
.app .service-row-load .service-row-data-load .desc span:nth-child(2) {
  width: 76%;
}
.app .service-row-load .service-row-data-load .desc span:nth-child(3) {
  width: 78%;
}
.app .service-row-load .service-row-data-load .button {
  width: 120px;
  height: 36px;
  border-radius: 8px;
  animation: var(--loading);
  transition: var(--transition);
}
.app .contact-row {
  display: flex;
}
.app .contact-row .contact-row-icon {
  width: 26px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.app .contact-row .contact-row-icon .icon {
  font-size: 12px;
  color: var(--primary-color);
}
.app .contact-row .contact-row-data {
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.app .contact-row .contact-row-data .title {
  margin-bottom: 10px;
  color: var(--primary-color);
  font: bold 12px var(--font-sans);
}
.app .contact-row .contact-row-data .link {
  margin-bottom: 10px;
  color: var(--color-black);
  font: normal 18px var(--font-sans);
}
.app .container {
  display: flex;
  margin: 0 20px;
  width: var(--wrapper);
}
.app .logo {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
}
.app .logo img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  pointer-events: none;
  -o-object-position: center center;
     object-position: center center;
}
.app hr {
  width: 100%;
  height: 1px;
  border: none;
  transition: var(--transition);
  background-color: var(--border-color);
}
.app .grid {
  gap: 18px;
  width: 100%;
  display: grid;
  padding: 40px 0;
  grid-template-columns: repeat(4, 1fr);
}
.app .no-found {
  gap: 20px;
  width: 100%;
  display: flex;
  min-height: 78vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.app .no-found .icon-no-found {
  z-index: 1;
  opacity: 0.8;
  position: absolute;
  pointer-events: none;
  color: var(--border-color);
  line-height: 400px !important;
  font: 700 400px var(--font-genos);
}
.app .no-found .title-no-found,
.app .no-found .text-no-found,
.app .no-found a {
  z-index: 2;
}
.app .no-found .title-no-found {
  font: bold 80px var(--font-genos);
  text-shadow: 2px 2px 10px var(--color-black);
}
.app .no-found .text-no-found {
  text-align: center;
  letter-spacing: 0.4px;
  line-height: 30px !important;
  font: normal 16px var(--font-sans);
  text-shadow: 2px 2px 10px var(--color-black);
}
.app .no-found .text-no-found br {
  display: none;
}
.app .no-found a {
  box-shadow: 2px 2px 10px var(--color-black);
}

@media screen and (max-width: 64em) {
  .header .container .flex .nav-menu {
    display: none !important;
  }
  .header .container .flex #btnMenu {
    display: flex !important;
  }
  .main .container .row {
    flex-direction: column !important;
  }
  .main .container .row .feed p {
    width: 70%;
    text-align: left;
    text-wrap: balance;
  }
  .main .container .row .feed p:first-child {
    text-align: center !important;
  }
  .main .container .row .feed p:nth-child(even) {
    text-align: right;
  }
  .main .container .row .feed .brand {
    display: flex !important;
  }
  .main .container .row .feed .brand img {
    margin-top: 0px !important;
    width: 70% !important;
  }
  .main .container .row .brand {
    display: none !important;
  }
  .carousel .carousel-images {
    height: 270px !important;
  }
  .carousel .carousel-images .images {
    -o-object-position: center !important;
       object-position: center !important;
  }
  .service-row .service-row-image img {
    height: 100%;
    aspect-ratio: none;
  }
  .service-row .service-row-data {
    gap: 18px !important;
  }
  .service-row .service-row-data h2 {
    font-size: 18px !important;
  }
  .service-row .service-row-data p {
    font-size: 13px !important;
  }
  .service-row-load .service-row-data-load {
    gap: 20px !important;
  }
  .service-row-load .service-row-data-load .title span {
    height: 30px !important;
  }
  .service-row-load .service-row-data-load .desc span {
    height: 20px !important;
  }
  .contact-row .contact-row-data .link {
    font-size: 16px !important;
  }
  .logo {
    height: 48px !important;
  }
  .grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .no-found .icon-no-found {
    font-size: 40vh !important;
  }
  .no-found .title-no-found {
    font-size: 40px !important;
  }
  .no-found .text-no-found br {
    display: flex !important;
  }
}
@media screen and (max-width: 640px) {
  .grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media screen and (max-width: 40em) {
  .header .container .flex {
    gap: 0px !important;
  }
  .header .container .flex .nav-menu {
    display: none !important;
  }
  .header .container .flex #btnAction {
    display: none !important;
  }
  .header .container .flex #btnMenu {
    display: flex !important;
  }
  .header .container .flex:nth-child(1) {
    width: 100%;
  }
  .header .container .flex:nth-child(2) {
    width: 30%;
  }
  .main .section .container .head-title {
    min-height: 180px !important;
  }
  .main .section .container .row p {
    width: 100% !important;
    text-align: center !important;
  }
  .main .section .container .row .brand img {
    width: 100% !important;
    display: flex !important;
  }
  .carousel .carousel-images {
    height: 210px !important;
    height: auto !important;
  }
  .carousel .carousel-images #carouselDestok {
    display: none;
  }
  .carousel .carousel-images #carouselMobile {
    display: flex !important;
  }
  .service-row {
    flex-direction: column;
  }
  .service-row .service-row-image {
    width: 100% !important;
  }
  .service-row .service-row-data {
    width: 100% !important;
    margin-left: 0 !important;
    align-items: center !important;
  }
  .service-row .service-row-data h2 {
    text-align: center;
    margin-top: 14px !important;
  }
  .service-row .service-row-data p {
    text-align: center;
    font-size: 12px !important;
  }
  .service-row-load {
    flex-direction: column;
  }
  .service-row-load .service-row-image-load {
    width: 100% !important;
  }
  .service-row-load .service-row-data-load {
    width: 100% !important;
    margin-left: 0px !important;
    align-items: center !important;
  }
  .service-row-load .service-row-data-load .title {
    margin-top: 14px;
    align-items: center !important;
  }
  .service-row-load .service-row-data-load .desc {
    align-items: center !important;
  }
  .logo img {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .no-found .icon-no-found {
    font-size: 22vh !important;
  }
}
@media screen and (max-width: 480px) {
  .grid {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}/*# sourceMappingURL=app.css.map */